import React, { useState, useEffect, useContext } from "react";
import * as styles from "./homepageWidget.module.scss";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DomainContext from "../../context/DomainContext";
import Kaufen from "../kaufen/Kaufen";

const HomepageWidget = props => {
  const {
    productList,
    getProductData,
    productData,
    variation,
    productPriceLabel,
  } = props;
  const [selectedTab, setSelectedTab] = useState("tab1");
  const [sellingMaterial, setSellingMaterial] = useState({});
  const [sellingQuantity, setSellingQuantity] = useState();
  const [sellerEmail, setSellerEmail] = useState("");
  const [weightUnit, setWeightUnit] = useState("ton");
  const [calculatedAmount, setCalculatedAmount] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);
  const [sellerZip, setSellerZip] = useState();

  const domain = useContext(DomainContext);

  const handleTabChange = selected => {
    setSelectedTab(selected);
  };

  const handleAmountCalculation = () => {
    setButtonClicked(true);
    if (domain === "ch") {
      const quantity = sellingQuantity * 1000;
      redirectTo(
        `anfrage/?materialId=${sellingMaterial.id}&email=&mu=kg&weight=${quantity}&recurrence=once`
      );
    } else if (
      parseInt(sellingQuantity, 10) &&
      sellingMaterial &&
      sellingQuantity <= productData.minimum
    ) {
      setCalculatedAmount("small");
    } else if (
      parseInt(sellingQuantity, 10) &&
      sellingMaterial &&
      sellingQuantity > productData.minimum
    ) {
      setCalculatedAmount("big");
    }
  };

  const handleGoToCartWithZip = () => {
    const quantity =
      weightUnit === "ton" ? sellingQuantity * 1000 : sellingQuantity;
    redirectTo(
      `co/cart/?productId=${sellingMaterial.id}&quantity=${quantity}&plz=${sellerZip}`,
      true
    );
  };

  const handleGoToCartWithEmail = () => {
    const quantity =
      weightUnit === "ton" ? sellingQuantity * 1000 : sellingQuantity;
    redirectTo(
      `anfrage/?materialId=${sellingMaterial.id}&email=${sellerEmail ||
        ""}&mu=kg&weight=${quantity}&recurrence=once`
    );
  };

  const redirectTo = (url, isExternalLink) => {
    if (isExternalLink) {
      window.location = `https://www.schrott24.de/${url}`;
    } else {
      window.location = `${window.location.protocol}//${window.location.host}/${url}`;
    }
  };

  const defaultProps = {
    options: productList,
    getOptionLabel: option => option.title || option.name,
  };

  useEffect(() => {
    if (sellingMaterial && sellingMaterial.id && sellingQuantity) {
      getProductData(sellingMaterial.id);
    }
    if (!sellingQuantity) {
      setCalculatedAmount("");
    }
  }, [sellingMaterial, sellingQuantity, getProductData]);

  return (
    <section className={styles.container} id="topPage">
      <div
        className={`${styles.hubBannerContainer} ${weightUnit === "kilo" &&
          styles.kiloBG} ${variation === "pricePage" && styles.pricePage}`}
        id="hubBannerContainer"
      >
        <div className={styles.wContainer}>
          <div className={styles.hubBannerFormContainer}>
            <div
              className={styles.imageMobileTop}
              id="selected_hubBanner_mobile_imgTop"
            ></div>
            <div className={styles.formHeader}>
              <div
                className={`${styles.tabOption} ${
                  selectedTab === "tab2" ? styles.hubBannerOptTwo : ""
                }`}
                id="selected_hubBannerTabOption"
              ></div>
              <div
                className={`
                  ${styles.firstHeading} ${
                  selectedTab === "tab1" ? styles.tabSelected : ""
                }`}
                data-container="buyerHubBannerContent"
                onClick={() => handleTabChange("tab1")}
              >
                Verkaufen
              </div>
              <div
                className={`
                  ${styles.secondHeading} ${
                  selectedTab === "tab2" ? styles.tabSelected : ""
                }`}
                data-container="sellerHubBannerContent"
                id="hubBannerSellerTabOption"
                onClick={() => handleTabChange("tab2")}
              >
                Kaufen
              </div>
            </div>
            <div className={styles.formBody}>
              <div
                className={`${styles.hubBannerContent} ${
                  selectedTab === "tab1" ? styles.activeOption : ""
                }`}
                id="buyerHubBannerContent"
              >
                <h1 className={styles.mainHeading}>
                  Finden Sie die besten{" "}
                  {variation === "pricePage" && productPriceLabel
                    ? productPriceLabel[0].toUpperCase() +
                      productPriceLabel.slice(1)
                    : "Preise"}{" "}
                  für Ihr Altmetall
                </h1>
                <form className={styles.dFlex}>
                  <div className={styles.inputContainer}>
                    <div
                      className={styles.inputWrapper}
                      id="hubBannerMaterialContainer"
                    >
                      <Autocomplete
                        {...defaultProps}
                        id="clear-on-blur"
                        freeSolo
                        disableListWrap
                        clearOnBlur
                        onChange={(event, value) => setSellingMaterial(value)}
                        className={styles.autoComplete}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Kategorie / Material"
                            margin="normal"
                            error={buttonClicked && !sellingMaterial}
                            helperText={
                              buttonClicked && !sellingMaterial
                                ? "Enter material"
                                : ""
                            }
                          />
                        )}
                      />
                    </div>
                    <div
                      className={styles.quantityContainer}
                      id="hubBannerQuantiyContainer"
                    >
                      <div
                        className={`${styles.secondFlex} ${domain === "ch" &&
                          styles.fullWidth}`}
                      >
                        <TextField
                          label="Menge (t)"
                          margin="normal"
                          error={buttonClicked && !sellingQuantity}
                          helperText={
                            buttonClicked && !sellingQuantity
                              ? "Ihre Menge eingeben"
                              : ""
                          }
                          onChange={event =>
                            setSellingQuantity(event.target.value)
                          }
                          className={styles.weight}
                        />
                        {domain !== "ch" && (
                          <div className={styles.checkBox}>
                            <div
                              className={styles.radioItemOne}
                              onClick={() => setWeightUnit("ton")}
                            >
                              <input
                                className={styles.formCheckInput}
                                type="radio"
                                name="inlineRadioOptions"
                                checked="true"
                                id="weightMultiplierSelectorTons"
                                data-multiplier="1000"
                                data-symbol="t"
                              />
                              <label
                                className={
                                  weightUnit === "ton"
                                    ? styles.selected
                                    : styles.unSelected
                                }
                                for="weightMultiplierSelectorTons"
                              >
                                <span
                                  className={
                                    weightUnit === "ton"
                                      ? styles.labelSelected
                                      : styles.labelUnSelected
                                  }
                                >
                                  t
                                </span>
                              </label>
                            </div>
                            <div
                              className={styles.radioItemTwo}
                              onClick={() => setWeightUnit("kilo")}
                            >
                              <input
                                className={styles.weightKg}
                                type="radio"
                                name="inlineRadioOptions"
                                id="weightMultiplierSelector"
                                data-multiplier="1"
                                data-symbol="kg"
                              />
                              <label
                                className={
                                  weightUnit === "kilo"
                                    ? styles.selected
                                    : styles.unSelected
                                }
                                for="weightMultiplierSelector"
                              >
                                <span
                                  className={
                                    weightUnit === "kilo"
                                      ? styles.labelSelected
                                      : styles.labelUnSelected
                                  }
                                >
                                  kg
                                </span>
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                      <span className={styles.errorMessage2}>
                        Ihre Menge eingeben
                      </span>
                    </div>
                  </div>
                </form>
                {!calculatedAmount && (
                  <button
                    className={styles.button}
                    id="buttonHubBannerFirstStep"
                    onClick={handleAmountCalculation}
                  >
                    WEITER
                  </button>
                )}
                {calculatedAmount === "small" ? (
                  <div
                    className={styles.smallAmount}
                    id="hubBanner-seller-smallAmountContainer"
                  >
                    <h3 className={styles.headingThree}>
                      Preis jetzt für Standort und Transportart optimieren
                    </h3>
                    <a className={styles.faqSupport} href="/faq/support">
                      So funktioniert's
                    </a>
                    <form className={styles.smallAmountForm}>
                      <div className={styles.zipInput}>
                        <span className={styles.dNone}>PLZ</span>{" "}
                        <input
                          autocomplete="off"
                          placeholder="PLZ"
                          type="text"
                          id="hubBannerPLZ"
                          name="fname"
                          onChange={event => setSellerZip(event.target.value)}
                          className={styles.standardTextInput}
                        />
                      </div>
                      <div className={styles.rangePrices}>
                        <span className={styles.standerdPlaceholderLabel}>
                          Geschätzter Preisbereich
                        </span>{" "}
                        <span
                          className={styles.rangeOfPrices}
                          id="hubBannerRangeOfPrices"
                        >
                          €{productData.range}
                        </span>
                      </div>
                    </form>
                    <button
                      className={styles.secondButton}
                      id="hubBannerRedirectCheckoutButton"
                      onClick={handleGoToCartWithZip}
                    >
                      ZUM WARENKORB
                    </button>
                  </div>
                ) : (
                  <></>
                )}

                {calculatedAmount === "big" ? (
                  <div
                    className={styles.seller}
                    id="hubBanner-seller-bigAmountContainer"
                  >
                    <h3 className={styles.headingFour}>
                      Erhalten Sie ein unverbindliches Verwertungsangebot
                    </h3>
                    <p className={styles.firstPara}>
                      Durch unser internationales Netzwerk aus spezialisierten
                      Recyclern &amp; Werken finden wir die beste
                      Verwertungslösung für Ihre Materialien.{" "}
                      <a className={styles.pro} href="/pro/">
                        Mehr zum PRO Service
                      </a>
                    </p>
                    <form className={styles.smallAmountForm2}>
                      <div className={styles.zipInput2}>
                        <TextField
                          name="email"
                          label="Firmen E-mail"
                          margin="normal"
                          id="hubBannerEmail"
                          onChange={event => setSellerEmail(event.target.value)}
                        />
                      </div>
                    </form>
                    <button
                      className={styles.formCheckButton}
                      id="hubBannerRedirectProButton"
                      onClick={handleGoToCartWithEmail}
                    >
                      WEITER
                    </button>
                    <div className={styles.termsConditions}>
                      <span className={styles.termsAndConditions}>
                        Wenn Sie fortfahren akzeptieren Sie unsere{" "}
                        <a
                          className={styles.faqDisclaimer}
                          href="/faq/disclaimer/"
                        >
                          Geschäftsbedingungen
                        </a>
                      </span>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className={styles.bigAmount}></div>
              </div>
              <div
                className={`${styles.kaufen} ${
                  selectedTab === "tab2" ? styles.activeOption : ""
                }`}
              >
                <Kaufen productList={productList} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomepageWidget;
